import * as React from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import GoogleRegister from "../SocialLogin/GoogleRegister.tsx";
import FaceBookRegister from "../SocialLogin/FacebookRegister.tsx";
import { googleResponse } from "../testVars";
import * as IdentityService from "../../services/identityService";

export default function CardSocialLogin({ setOtherError, isLogin }) {
  let googleLogin = isLogin
    ? IdentityService.signInGoogle
    : IdentityService.registerGoogle;

  let facebookLogin = isLogin
    ? IdentityService.signInFacebook
    : IdentityService.registerFacebook;
  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        justifyItems: "center",
      }}
    >
      <GoogleRegister setOtherError={setOtherError} login={googleLogin} />
      <Box sx={{ marginTop: 1 }}>
        <FaceBookRegister setOtherError={setOtherError} login={facebookLogin}></FaceBookRegister>
      </Box>
    </Box>
  );
}
