import * as React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "../../css/index.css";

export default function FacebookButton({ onClick, loading }) {
  //You could use the loading prop to show loading,
  //but people aren't gonna be looking at the button anyway..
  if (loading) console.log("Logging in with Facebook...");

  return (
    <button className="facebook-button" onClick={onClick}>
      <Box style={{ padding: 7, paddingLeft: 3, marginRight: 9 }}>
        <FacebookIcon></FacebookIcon>
      </Box>
      <Typography fontWeight="bold" fontSize={14}>
        Continue with Facebook
      </Typography>
    </button>
  );
}
