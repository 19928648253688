import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, Link } from "@mui/material";
import logo from "../../assets/SharpRank-primary-horizontal.png";

export default function CardHeader({ text, toggleForm, isLogin }) {
  let toggleText = isLogin ? (
    <Typography>
      Don't have an account?{" "}
      <Link href="#" onClick={toggleForm} sx={{ fontWeight: "bold" }}>
        Sign up
      </Link>
    </Typography>
  ) : (
    <Typography>
      Already have an account?{" "}
      <Link href="#" onClick={toggleForm} sx={{ fontWeight: "bold" }}>
        Log in
      </Link>
    </Typography>
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt="SharpRankLogo"
        style={{ maxWidth: "40%", height: "auto", minWidth: "300px" }}
      ></img>
      <Typography
        color="primary"
        sx={{ paddingTop: 2, paddingBottom:1, fontSize: 18, fontWeight: "bold" }}
      >
        {text ?? "Sign Up"}
      </Typography>
      {toggleText}
    </Box>
  );
}
