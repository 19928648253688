import React from "react";
import Register from "../components/RegisterCard/RegisterCard.tsx";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import backgroundImage from "../assets/stadium.jpg";

export default function RegisterPage() {
  return (
    <Container
      component="main"
      sx={{
        height: "100%",
        backgroundImage: window.innerWidth > 600 ? `url(${backgroundImage})` : null,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "stretch",
        minWidth: "100%"
      }}
    >
      <CssBaseline />
      <Box
        id="my-box"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Register />
      </Box>
    </Container>
  );
}
