import * as React from "react";
import Link from "@mui/material/Link";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Card from "@mui/material/Card";
import CardHeader from "./CardHeader.tsx";
import FormHelperText from "@mui/material/FormHelperText";
import CardForm from "./CardForm.tsx";
import CardSocialLogin from "./CardSocialLogin.tsx";
import CardLoginForm from "./CardLoginForm.tsx";

export default function RegisterCard({ referralCode }) {
  const [otherError, setOtherError] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  let errorMessage =
    otherError == "" ? null : (
      <FormHelperText error={true} sx={{ mx: 2, mb: 2 }}>
        {otherError}
      </FormHelperText>
    );

  let toggleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLogin(!isLogin);
    console.log(isLogin);
  };
  
  let headerText = isLogin
    ? "Log in and accept your invite!"
    : "Sign up and join the Ranks!";

  let cardForm = isLogin ? (
    <CardLoginForm setOtherError={setOtherError}></CardLoginForm>
  ) : (
    <CardForm setOtherError={setOtherError}></CardForm>
  );

  return (
    <Card raised sx={{ maxWidth: 400, padding: 3, minHeight: 650 }}>
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: 350,
        }}
      >
        <CardHeader
          text={headerText}
          toggleForm={toggleForm}
          isLogin={isLogin}
        ></CardHeader>
        {cardForm}
        {errorMessage}
        <CardSocialLogin
          setOtherError={setOtherError}
          isLogin={isLogin}
        ></CardSocialLogin>
        <Copyright sx={{ mt: 2, mb: 2 }} />
      </Box>
    </Card>
  );
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://sharprank.com/">
        SharpRank
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
