import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useParams, useSearchParams, To } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import RedirectDialog from "../RedirectDialog.tsx";
import FormHelperText from "@mui/material/FormHelperText";

export default function CardLoginForm({ setOtherError }) {
  const [redirecting, setRedirecting] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passError, setPassError] = useState(null);
  const [secondaryText, setSecondaryText] = useState(<></>);
  const [queryParams] = useSearchParams();
  const redirectUrl =
    queryParams.get("redirectUrl") ?? "https://join.sharprank.com/appstore";
  const params = useParams();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const loginUrl = `${process.env.REACT_APP_API_URL}/v2/Identity/Login`;
      const referralCode =
        queryParams.get("referralCode") ?? params.referralCode;
      const response = await axios.post(loginUrl, {
        email: data.get("email"),
        password: data.get("password"),
        referralCode: referralCode,
      });
      const text = !response.data.leagueName ? (
        <></>
      ) : (
        <Typography sx={{ fontSize: 16 }}>
          Invited to {response.data.leagueName}
        </Typography>
      );
      setSecondaryText(text);
      redirect();
    } catch (error) {
      const badResponse = error.response;
      if (badResponse.status == 409) {
        redirect();
        return;
      }
      
      if(badResponse.status == 410){
        setOtherError(`Invite link has already been used.`)
        return;
    }

      debugger;

      if (badResponse.data?.message == "Invalid referral code.") {
        setOtherError(`Invalid referral code: ${params.referralCode}`);
        return;
      }

      if (
        badResponse.data?.message ==
        "Could not find the specified username/password combination."
      ) {
        setOtherError(`Invalid referral code: ${params.referralCode}`);
        return;
      }

      if (badResponse.data.errors) {
        console.log(badResponse.data.errors);
        setEmailError(badResponse.data.errors.Email?.[0]);
        setPassError(badResponse.data.errors.Password?.[0]);
        return;
      }

      setOtherError("Network Error.");
    }
  };

  const redirect = () => {
    setEmailError(null);
    setPassError(null);
    setRedirecting(true);
    console.log(redirectUrl);
    setTimeout(() => window.location.replace(redirectUrl), 1800);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          helperText={emailError}
          error={!!emailError}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          helperText={passError}
          error={!!passError}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          <Typography
            fontWeight="bold"
            fontSize={14}
            py="2px"
            sx={{ letterSpacing: 0.5 }}
          >
            Log in
          </Typography>
        </Button>
      </Box>
      <RedirectDialog
        open={redirecting}
        secondaryText={secondaryText}
      ></RedirectDialog>
    </>
  );
}
