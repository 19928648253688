import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";

export default function RedirectDialog({ open, secondaryText }) {
  return (
    <Dialog open={open}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        {secondaryText}
        <Typography color="primary" sx={{ fontSize: 16, fontWeight: "bold" }}>
          Redirecting
        </Typography>
        <CircularProgress color="secondary"></CircularProgress>
      </Card>
    </Dialog>
  );
}
