import * as React from "react";
import { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "../../css/index.css";
import FacebookButton from "./FacebookButton.tsx";
import Typography from "@mui/material/Typography";
import { useParams, useSearchParams, To } from "react-router-dom";
import { registerFacebook } from "../../services/identityService";
import RedirectDialog from "../RedirectDialog.tsx";

export default function FacebookRegister({ setOtherError, login }) {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const redirectUrl =
    queryParams.get("redirectUrl") ?? "https://join.sharprank.com/appstore";
  const [redirecting, setRedirecting] = useState(false);
  const [secondaryText, setSecondaryText] = useState(<></>);

  const handleFailure = (e: any) => {
    debugger;
    console.log("facebook error");

    if (e?.response?.status == 410) {
      setOtherError(`Invite link has already been used.`);
      return;
    }
    if (e.error == "user already exists") {
      //setSecondaryText(userAlreadyExistsText);
      setRedirecting(true);
      setTimeout(() => window.location.replace(redirectUrl), 2000);
      return;
    }

    if (e.response.data.message == "Invalid referral code.") {
      setOtherError(`Invalid referral code: ${params.referralCode}`);
      return;
    }

    setOtherError("Network error");
    console.error(e);
  };

  const responseFacebook = async (facebookLoginObject) => {
    try {
      const referralCode =
        queryParams.get("referralCode") ?? params.referralCode;
      setOtherError("");
      const serverResponse = await login(facebookLoginObject, referralCode);
      console.log(serverResponse);
      debugger;
      setRedirecting(true);
      const text = !serverResponse.leagueName ? (
        <></>
      ) : (
        <Typography sx={{ fontSize: 16 }}>
          Invited to {serverResponse.leagueName}
        </Typography>
      );
      setSecondaryText(text);
      setTimeout(() => window.location.replace(redirectUrl), 2000);
    } catch (error) {
      handleFailure(error);
    }
  };

  return (
    <>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email"
        scope="public_profile,email"
        callback={responseFacebook}
        onFailure={handleFailure}
        textButton="Facebook Login"
        icon="fa-facebook"
        render={(renderProps) => (
          <FacebookButton
            onClick={renderProps.onClick}
            loading={renderProps.isProcessing}
          />
        )}
      />

      <RedirectDialog
        open={redirecting}
        secondaryText={secondaryText}
      ></RedirectDialog>
    </>
  );
}
