import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useParams, useSearchParams, To } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import RedirectDialog from "../RedirectDialog.tsx";
import FormHelperText from "@mui/material/FormHelperText";

export default function CardForm({ setOtherError }) {
    const [redirecting, setRedirecting] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [passError, setPassError] = useState(null);
    const [confirmPassError, setConfirmPassError] = useState(null);
    const [secondaryText, setSecondaryText] = useState(<></>);
    const [queryParams] = useSearchParams();
    const redirectUrl =
        queryParams.get("redirectUrl") ?? "https://join.sharprank.com/appstore";
    const params = useParams();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const data = new FormData(event.currentTarget);
            const registerUrl = `${process.env.REACT_APP_API_URL}/v2/Identity/Register`;
            console.log(params);
            const serverResponse = await axios.post(registerUrl, {
                email: data.get("email"),
                password: data.get("password"),
                confirmPassword: data.get("confirm-password"),
                referralCode: params.referralCode,
            });
            debugger;
            const text = !serverResponse.data.leagueName ? <></> : (
                <Typography sx={{ fontSize: 16 }}>
                  Invited to {serverResponse.data.leagueName}
                </Typography>
              );
            setSecondaryText(text)
            redirect();
        } catch (error) {
            const badResponse = error.response;
            if (badResponse.status == 409) {
                redirect();
                return;
            }

            if(badResponse.status == 410){
                setOtherError(`Invite link has already been used.`);
                return;
            }

            debugger;

            if (badResponse.data.message == "Invalid referral code.") {
                setOtherError(`Invalid referral code: ${params.referralCode}`);
                return;
            }

            if (badResponse.data.errors) {
                console.log(badResponse.data.errors);
                setEmailError(badResponse.data.errors.Email?.[0]);
                setPassError(badResponse.data.errors.Password?.[0]);
                setConfirmPassError(
                    badResponse.data.errors.ConfirmPassword?.[0]
                );
                return;
            }

            setOtherError("Network Error.");
        }
    };

    const redirect = () => {
        setEmailError(null);
        setPassError(null);
        setConfirmPassError(null);
        setRedirecting(true);
        console.log(redirectUrl);
        setTimeout(() => window.location.replace(redirectUrl), 2000);
    };

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    helperText={emailError}
                    error={!!emailError}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    helperText={passError}
                    error={!!passError}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm-password"
                    label="Confirm Password"
                    type="password"
                    id="confirm-password"
                    autoComplete="confirm-password"
                    helperText={confirmPassError}
                    error={!!confirmPassError}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                >
                    <Typography
                        fontWeight="bold"
                        fontSize={14}
                        py="2px"
                        sx={{ letterSpacing: 0.5 }}
                    >
                        Sign up
                    </Typography>
                </Button>
            </Box>
            <RedirectDialog
                open={redirecting}
                secondaryText={secondaryText}
            ></RedirectDialog>
        </>
    );
}
