import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./components/RegisterCard/RegisterCard.tsx";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import RegisterPage from "./routes/RegisterPage.tsx";

// ========================================

const theme = createTheme({
    palette: {
        primary: {
            main: "#00295E",
            light: "#FFFFFF",
        },
        secondary: {
            main: "#00ADEF",
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RegisterPage />}></Route>
                <Route path=":referralCode" element={<RegisterPage />}></Route>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);
