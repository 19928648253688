import axios from "axios";

export async function registerGoogle(googleLoginObject, referralCode) {
  const payload = {
    token: googleLoginObject.tokenId,
    referralCode: referralCode,
  };

  const registerUrl = `${process.env.REACT_APP_API_URL}/v2/Identity/GoogleLogin`;
  const response = await axios.post(registerUrl, payload);

  if (response.status !== 200) throw response;
  return response.data;
}

export async function registerFacebook(facebookLoginObject, referralCode) {
  const payload = {
    token: facebookLoginObject.accessToken,
    referralCode: referralCode,
  };

  const registerUrl = `${process.env.REACT_APP_API_URL}/v2/Identity/FacebookLogin`;
  const response = await axios.post(registerUrl, payload);

  if (response.status !== 200) throw response;
  return response.data;
}

export async function signInFacebook(facebookLoginObject, referralCode) {
  const payload = {
    token: facebookLoginObject.accessToken,
    referralCode: referralCode,
  };

  const registerUrl = `${process.env.REACT_APP_API_URL}/v2/Identity/FacebookLogin`;
  const response = await axios.post(registerUrl, payload);

  if (response.status !== 200) throw response;
  return response.data;
}

export async function signInGoogle(googleLoginObject, referralCode) {
  const payload = {
    token: googleLoginObject.tokenId,
    referralCode: referralCode,
  };

  const registerUrl = `${process.env.REACT_APP_API_URL}/v2/Identity/GoogleLogin`;
  const response = await axios.post(registerUrl, payload);

  if (response.status !== 200) throw response;
  return response.data;
}
